<template>
  <div class="bookings-list mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">
            Buchungen
          </h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            v-if="renderDatatable"
            :url="'backoffice/bookings'"
            :search-enabled="true"
            :headers="headers"
            :filters="filters"
            sort-by="-created_at"
            ref="bookingsDatatable"
          >
            <template v-slot:[`item.id`]="{ item }">
              <router-link :to="`/bookings/${item.id}`">
                {{ item.id }}
              </router-link>
            </template>
            <template v-slot:[`item.refid`]="{ item }">
              <router-link :to="`/bookings/${item.id}`">
                {{ item.refid }}
              </router-link>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | formatDateTime }}
            </template>
            <template v-slot:[`item.creator`]="{ item }">
              {{ item.creator.first_name }} {{ item.creator.last_name }}
            </template>
            <template v-slot:[`item.room`]="{ item }">
              {{ item.room.name }}
            </template>
            <template v-slot:[`item.date`]="{ item }">
              {{ item.starts_at | formatDate }}
            </template>
            <template v-slot:[`item.time`]="{ item }">
              {{ item.starts_at | formatTime }} -
              {{ item.ends_at | formatTime }} Uhr
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <booking-status-label :booking="item" small />
            </template>
            <template v-slot:[`item.payment`]="{ item }">
              <v-tooltip top v-if="item.payment_status === 'confirmed'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="success">
                    check
                  </v-icon>
                </template>
                <span>Verbucht</span>
              </v-tooltip>
              <v-tooltip top v-if="item.payment_status === 'account'">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="info">
                    receipt
                  </v-icon>
                </template>
                <span>Auf Rechnung</span>
              </v-tooltip>
              <span class="pl-2">
                CHF {{ item.total_amount | formatCurrency }}
              </span>
            </template>
            <template v-slot:[`item.document`]="{ item }">
              <tooltip-button
                v-if="item.function_sheet_url"
                icon="print"
                text="Function Sheet"
                color="default"
                :dark="false"
                @click="exportFunctionSheet(item)"
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
              <tooltip-button
                v-if="item.receipt_url"
                icon="receipt"
                text="Beleg"
                color="default"
                :dark="false"
                @click="exportReceipt(item)"
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <tooltip-button
                icon="delete"
                text="Löschen"
                color="default"
                :dark="false"
                @click="deleteBooking(item)"
                classNames="elevation-0 ml-1 mt-1 mb-1"
                position="top"
                size="x-small"
              />
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <delete-booking-dialog
      ref="deleteBookingDialog"
      @deleted="$refs.bookingsDatatable.getDataFromApi()"
    />

    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.bookingsDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import RemoteDataTable from "@/components/RemoteDataTable";
import BookingStatusLabel from "@/components/labels/BookingStatusLabel";
import TooltipButton from "@/components/forms/TooltipButton";
import DeleteBookingDialog from "@/components/dialogs/DeleteBookingDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
  components: {
    RemoteDataTable,
    BookingStatusLabel,
    TooltipButton,
    DeleteBookingDialog,
    DeleteDialog
  },

  data: () => ({
    renderDatatable: false,
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      {
        text: "Buchungsnummer",
        align: "left",
        sortable: true,
        value: "refid"
      },

      {
        text: "Erstellt am",
        align: "left",
        sortable: true,
        value: "created_at"
      },
      {
        text: "Erstellt von",
        align: "left",
        sortable: false,
        value: "creator"
      },
      { text: "Raum", align: "left", sortable: false, value: "room" },
      { text: "Datum", align: "left", sortable: true, value: "date" },
      { text: "Uhrzeit", align: "left", sortable: true, value: "time" },
      { text: "Status", align: "left", sortable: true, value: "status" },
      { text: "Betrag", align: "right", sortable: false, value: "payment" },
      { text: "Dokumente", align: "right", sortable: false, value: "document" },
      { text: "Aktionen", align: "right", sortable: false, value: "actions" }
    ],
    search: null,
    filters: [
      {
        type: "select",
        field: "room_id",
        label: "Raum",
        values: [],
        default: null
      },
      {
        type: "datepicker",
        field: "date",
        label: "Datum",
        default: null
      }
    ]
  }),

  created() {
    this.fetchRooms();
  },

  methods: {
    /**
     * Fetch rooms from server
     */
    fetchRooms() {
      this.$api.http.get(`backoffice/rooms?_per_page=99999`).then(response => {
        this.filters[0].values = response.data.data.map(room => {
          return {
            text: `${room.name}`,
            value: room.id
          };
        });
        this.filters[0].default = parseInt(this.$route.query._room);
        this.renderDatatable = true;
      });
    },

    /**
     * Export a function sheet.
     */
    exportFunctionSheet(booking) {
      window.open(booking.function_sheet_url, "_blank");
    },

    /**
     * Export a receipt.
     */
    exportReceipt(booking) {
      window.open(booking.receipt_url, "_blank");
    },

    /**
     * Delete booking.
     */
    deleteBooking(booking) {
      if (booking.status === "blocker") {
        this.$refs.deleteDialog.entityId = booking.id;
        this.$refs.deleteDialog.entityName = `#${booking.refid}`;
        this.$refs.deleteDialog.entityUrl = "bookings";
        this.$refs.deleteDialog.open = true;
      } else {
        this.$refs.deleteBookingDialog.booking = booking;
        this.$refs.deleteBookingDialog.open = true;
      }
    }
  }
};
</script>

<style lang="scss">
.bookings-list {
  a {
    text-decoration: none;
    color: var(--v-primary-darken1);
  }
}
</style>
