<template>
  <v-dialog v-model="open" scrollable width="600">
    <v-card v-if="booking">
      <v-card-title :class="[color, 'white--text']">
        <span class="subtitle-1">Vorsicht</span>
      </v-card-title>

      <v-card-text class="mt-3">
        <p>
          Die Buchung wird endgültig aus dem System gelöscht. Es werden
          <strong>keine Rückzahlungen</strong> erfasst, dazu muss sie erst
          storniert werden.
        </p>
        <p>
          Um fortzufahren, muss die Referenznummer
          <strong>{{ booking.refid }}</strong> im Feld unten bestätigt werden.
        </p>
        <v-text-field
          outlined
          dense
          label="Referenznummer"
          type="text"
          v-model="refidConfirmation"
        />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click="abort()">
          Nein
        </v-btn>
        <v-btn :color="color" text @click="deleteEntity()" :disabled="!valid">
          Ja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    refidConfirmation: null,
    booking: null,
    color: "error"
  }),

  computed: {
    valid() {
      return (
        this.refidConfirmation && this.booking.refid === this.refidConfirmation
      );
    }
  },

  methods: {
    /**
     * Delete entity.
     */
    deleteEntity() {
      this.$api.http
        .delete(`backoffice/bookings/${this.booking.id}`)
        .then(response => {
          this.open = false;
          this.$emit("deleted");
        })
        .catch(error => {
          this.$toast.error(`${this.entityName} konnte nicht gelöscht werden.`);
        });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
    }
  }
};
</script>
