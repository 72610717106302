var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bookings-list mt-4 ml-8 mr-8"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"display-2 mb-5"},[_vm._v(" Buchungen ")])])],1),_c('v-card',{staticClass:"elevation-0",attrs:{"color":"default","outlined":""}},[_c('v-card-text',[(_vm.renderDatatable)?_c('remote-data-table',{ref:"bookingsDatatable",attrs:{"url":'backoffice/bookings',"search-enabled":true,"headers":_vm.headers,"filters":_vm.filters,"sort-by":"-created_at"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/bookings/" + (item.id))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.refid",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/bookings/" + (item.id))}},[_vm._v(" "+_vm._s(item.refid)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created_at))+" ")]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.creator.first_name)+" "+_vm._s(item.creator.last_name)+" ")]}},{key:"item.room",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.room.name)+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.starts_at))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.starts_at))+" - "+_vm._s(_vm._f("formatTime")(item.ends_at))+" Uhr ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('booking-status-label',{attrs:{"booking":item,"small":""}})]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [(item.payment_status === 'confirmed')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v(" check ")])]}}],null,true)},[_c('span',[_vm._v("Verbucht")])]):_vm._e(),(item.payment_status === 'account')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"info"}},on),[_vm._v(" receipt ")])]}}],null,true)},[_c('span',[_vm._v("Auf Rechnung")])]):_vm._e(),_c('span',{staticClass:"pl-2"},[_vm._v(" CHF "+_vm._s(_vm._f("formatCurrency")(item.total_amount))+" ")])]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.function_sheet_url)?_c('tooltip-button',{attrs:{"icon":"print","text":"Function Sheet","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.exportFunctionSheet(item)}}}):_vm._e(),(item.receipt_url)?_c('tooltip-button',{attrs:{"icon":"receipt","text":"Beleg","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.exportReceipt(item)}}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-button',{attrs:{"icon":"delete","text":"Löschen","color":"default","dark":false,"classNames":"elevation-0 ml-1 mt-1 mb-1","position":"top","size":"x-small"},on:{"click":function($event){return _vm.deleteBooking(item)}}})]}}],null,true)}):_vm._e()],1)],1)],1),_c('delete-booking-dialog',{ref:"deleteBookingDialog",on:{"deleted":function($event){return _vm.$refs.bookingsDatatable.getDataFromApi()}}}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":function($event){return _vm.$refs.bookingsDatatable.getDataFromApi()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }